<template>
  <div>
    <div class="banner">
      <Skeleton height="190px" width="100%" />
    </div>
    <div class="notice">
      <Skeleton height="38px" width="100%" />
    </div>
    <div class="typeList">
      <Skeleton height="25px" width="40px" />
      <Skeleton height="25px" width="40px" />
      <Skeleton height="25px" width="40px" />
    </div>
    <div class="card">
      <Skeleton height="250px" width="100%" />
    </div>
    <div class="cardInfo">
      <div class="left">
        <Skeleton height="25px" width="140px" />
        <Skeleton style="margin-top: 10px" height="20px" width="100px" />
      </div>
      <div class="right">
        <Skeleton height="20px" width="100px" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../../components/skeleton';
export default {
  components: { Skeleton },
  name: 'HomeLoading',
};
</script>

<style lang="less" scoped>
.typeList {
  padding: 0 20px;
  .skeleton {
    margin-right: 20px;
  }
}
.card {
  padding: 0 20px;
}
.cardInfo {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 20px;
  .left {
    display: flex;
    flex-direction: column;
  }
}
</style>
