<template>
  <HomeLoading v-if="isInitLoading" />
  <div class="box" v-else>
    <Header isHideLeft>
      <template>华夏数艺</template>
      <template v-slot:right>
        <img
          v-if="unreadInfo.ifread"
          @click="goTo('/messageNotification')"
          style="max-width: 20px"
          src="../../assets/images/msg.png"
          alt=""
        />
        <img
          v-else
          @click="goTo('/messageNotification')"
          style="max-width: 20px"
          src="../../assets/images/msg1.png"
          alt=""
        />
      </template>
    </Header>
    <!--  -->
    <!-- 轮播 -->
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in imageList" :key="index">
          <img @click="ban(item)" :src="item.pic_url" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="announcement">
      <swiper
        :options="swiperOption"
        ref="mySwiper"
        style="height: 38px; line-height: 38px; padding: 0 15px"
      >
        <swiper-slide
          class="trumpet_box"
          v-for="item in getNotice"
          :key="item.index"
          style="font-size: 12px; line-height: 38px"
        >
          <div class="trumpet_box" @click="banner(item.id)">
            <div class="trumpet_box">
              <img
                class="trumpet"
                src="../../../static/image/announcemen.png"
                alt=""
              />
              <div class="trumpet_tow">{{ item.title }}</div>
            </div>
            <div class="right"></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <!-- <van-swipe
      left-icon="volume-o"
      style="height: 38px; padding-left: 10px"
      :autoplay="3000"
      vertical
    >
      <van-swipe-item
        class="trumpet_box"
        style="font-size: 12px; line-height: 38px"
        v-for="item in getNotice"
        :key="item.index"
        @click="banner(item.id)"
      >
        <img class="trumpet" src="../../../static/image/gonggao-1.png" alt="" />
        <div class="trumpet_tow">{{ item.title }}</div>
      </van-swipe-item>
    </van-swipe> -->
    <div class="but_two">
      <div class="yushouhuore_but1" @click="$router.push('/specialActivity')">
        <img src="../../assets/images/huodong.png" alt="" />
        <span>活动专区</span>
      </div>
      <div class="announcement_but" @click="announcement">
        <img src="../../../static/image/announcement.png" alt="" />
        <span>官方公告</span>
      </div>
      <!-- <div class="yushouhuore_but" @click="presell">
        <img src="../../../static/image/yushouhuore.png" alt="" />
        <span>预售专区</span>
      </div> -->
      <div class="yushouhuore_but" @click="presell">
        <img src="../../../static/image/zhuanji.png" alt="" />
        <span>过往专辑</span>
      </div>
    </div>

    <!-- 文化惠民促销区 -->
    <div class="wenhua" v-if="promotionListFilter.length">
      <div
        class="wenhuaItem"
        @click="bazaar(item)"
        v-for="item in promotionListFilter"
        :key="item.id"
      >
        <div class="tag" v-if="item.count">剩余:{{ item.count }}</div>
        <div class="tag" v-else>已售罄</div>
        <img :src="item.img" alt="" />
      </div>
      <div class="zhuanqu" @click="goTo('/zhuanqu')">进入专区</div>
    </div>

    <div class="neirong">
      <div
        v-for="list in anyHomeShopType"
        :key="list.id"
        @click="ternary(list)"
        :class="hotKey == list.key ? 'listp' : 'list'"
      >
        {{ list.name }}
      </div>
    </div>
    <!-- 列表展示 -->
    <div
      class="emptyBox"
      v-if="!Goodslist.length && !albumList.length && !list.length"
    >
      ~ 暂无数据 ~
    </div>
    <div style="padding-bottom: 50px; position: relative; z-index: 1" v-else>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          offset="50"
          @load="onLoad"
          :immediate-check="false"
        >
          <div id="box" v-for="item in Goodslist" :key="item.id">
            <!-- 时间 -->
            <div
              class="predict"
              v-if="item.buyTime != 0"
              v-show="(predic = true)"
            >
              预计开放时间：
              {{ item.buyTime | limitTimeFilter }}
            </div>
            <div
              class="tag"
              v-if="
                item.dealNum >= item.stockNum &&
                (item.blindGoodsId == 0 || add == 0)
              "
            >
              已售罄
            </div>
            <div
              class="tag"
              v-else-if="
                item.dealNum < item.stockNum &&
                item.isHot != 1 &&
                item.buyTime == 0
              "
            >
              抢购中
            </div>
            <div class="commodity" @click="particulars(item)">
              <img
                v-if="hotKey === 'isHot'"
                :src="item.listPic"
                alt=""
                class="commodity_img"
              />
              <img v-else :src="item.boxPic" alt="" class="commodity_img" />
              <div class="commodity_name">{{ item.name }}</div>
              <!-- <div v-if="hotKey === 'isOnly'" class="mony_tow">
                ￥{{ item.fee }}
              </div> -->
              <div class="commodity_particulars">
                <div class="particulars_fundamental">
                  <div class="particulars_fundamental_name">
                    {{ item.username || item.craftsman.username }}
                  </div>
                  <div class="creation">创作者/版权方</div>
                </div>
                <div class="particulars_price">
                  <span>￥</span>
                  <span>{{
                    hotKey === "isOnly" ? item.fee : item.applyPrice
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="box1"
            @click="$router.push(`/albumDetail?id=${item.id}`)"
            v-for="item in albumList"
            :key="item.id"
          >
            <img :src="item.pic" alt="" />
            <div class="title">{{ item.name }}</div>
            <div class="subTitle">{{ item.describe }}</div>
          </div>
          <div
            class="shengou"
            v-for="item in list"
            :key="item.id"
            @click="goTo1(item)"
          >
            <div class="img">
              <img :src="item.listPic" alt="" />
              <div class="timeOut" v-if="item.runBeginTime || item.runEndTime">
                <img src="../../../static/image/time.png" alt="" />
                <span v-if="item.runBeginTime"
                  >开始倒计时: {{ item.runBeginTime | limitTimeFilter }}</span
                >
                <span v-else-if="item.runEndTime && !item.runBeginTime"
                  >结束倒计时: {{ item.runEndTime | limitTimeFilter }}</span
                >
              </div>
              <div class="over" v-else>
                <img src="../../../static/image/over.png" alt="" />
              </div>
              <div class="tag" v-if="item.isApply">已申购</div>
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="subTitle">
              申购时间: {{ item.applyBegin }} 至 {{ item.applyEnd }}
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <van-overlay :show="none">
      <div class="wrapper">
        <div class="block">
          <div>尚未登录，请先进行登录</div>
          <div class="over_box">
            <div class="over_none" @click="cancel">取消</div>
            <div class="over_en" @click="confirm">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 实名认证  -->
    <van-overlay :show="autonym">
      <div class="wrapper">
        <div class="block">
          <div>你还未实名认证</div>
          <div>请先实名认证</div>
          <div class="over_box">
            <div class="over_none" @click="cancel_one">取消</div>
            <div class="over_en" @click="confirm_tow">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 预售专区 -->
    <!-- 消息弹框 -->
    <van-overlay :show="popupInfo.ispopup === 1 && isShowPopBox">
      <div class="wrapper">
        <div class="popImgbg">
          <div>
            <img :src="popupInfo.img" alt="" @click="pushBanner" />
            <i @click="closePopbox"></i>
          </div>
        </div>
      </div>
    </van-overlay>

    <div class="tips" v-if="isShowReminder">
      <div class="tipsBox">
        <div class="title">账号安全提醒</div>
        <div class="info">
          检测到您的账号暂未绑定手机号，部分功能不可用，为保证您的权益，请尽快绑定手机号。
        </div>
        <div class="btn">
          <div class="btnRight" @click="goTo('/bindingPhone')">去绑定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { formateTimeStamp } from "../../utils/tools";
import HomeLoading from "./HomeLoading.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: { HomeLoading, swiper, swiperSlide },
  data() {
    return {
      popupInfo: {},
      albumList: [],
      promotionList: [],
      isShowPopBox: false,
      isShowReminder: false,
      current: 0,
      swiperOption: {
        loop: true,
        direction: "vertical",
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      isInitLoading: true,
      particularsPrice: false, //价钱
      predic: false, //倒计时
      show: false,
      active: 2,
      activea: 0,
      imageList: [],
      getNotice: [], //公告列表信息
      id: "",
      key: "",
      anyHomeShopType: [], // 首页分类列表
      isvisited: 0, //默认样式
      Goodslist: [], //首页商品列表
      add: 1,
      e: "",
      kay: "",
      none: false,
      autonym: false,
      time: "", //当前时间
      ticker: null,
      ticker1: null,
      loading: false,
      finished: false,
      refreshing: false,
      goodsListParam: {
        pageindex: 1,
        pagesize: 20,
      },
      hotKey: "isHot",
      loadMore: true,
      unreadInfo: {},
      isLogin: false,
      list: [],
    };
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.cb);
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    if (this.ticker1) {
      clearInterval(this.ticker1);
    }
  },
  computed: {
    promotionListFilter() {
      if (this.promotionList.length > 2) {
        return this.promotionList.slice(0, 2);
      }
      return this.promotionList;
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", this.cb);
    this.isInitLoading = true;
    this.isLogin = localStorage.getItem("token");
    // code码
    if (this.$route.query.code) {
      sessionStorage.setItem("regcode", this.$route.query.code);
    }
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    if (this.ticker1) {
      clearInterval(this.ticker1);
    }
    this.beginTimer();
    this.getPromotionList();
    // 是否实名？  是否注册？ 是否登录
    let tok = localStorage.getItem("token");
    if (tok == null) {
      this.none = true;
    } else {
      this.none = false;
    }

    // 个人信息----弹框提示
    let message = {
      token: localStorage.getItem("token"),
    };

    this.$api.Userinfo(message).then((res) => {
      if (res.data.idcardStatus != 1) {
        this.autonym = true;
      } else {
        this.autonym = false;
      }
      if (res.data.mailBind) {
        this.isShowReminder = true;
      }
    });

    // 首页商品列表
    let params = {
      pageindex: 1,
      pagesize: 5,
      type: 0,
    };

    // top轮播图
    this.$api.Banner().then((res) => {
      if (res.code == 0) {
        this.imageList = res.data;
      } else {
        this.$toast(res.msg);
      }
    });

    // 首页分类列表
    this.$api.anyHomeShopType().then((res) => {
      if (res.code == 0) {
        this.anyHomeShopType = res.data;
        this.ternary(res.data[0]);
      }
    });

    // 首页公告轮播
    this.$api.getNotice(params).then((res) => {
      if (res.code == 0) {
        this.getNotice = res.data;
      }
    });
    if (this.isLogin) {
      this.unread();
      this.popup();
    }
  },
  filters: {
    limitTimeFilter(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        let txt = `${formateLimitTimes.day} 天 ${formateLimitTimes.hour} 时 ${formateLimitTimes.min} 分	${formateLimitTimes.seconds} 秒`;
        txt = `${Number(formateLimitTimes.day)} 天 ${
          formateLimitTimes.hour
        } 时 ${formateLimitTimes.min} 分   ${formateLimitTimes.seconds} 秒`;
        return txt;
      } else {
        const twtxt = `0 天 0 时 0 分 0 秒`;
        return twtxt;
      }
    },
  },
  methods: {
    // 商品详情
    bazaar(item) {
      console.log(item);
      if (!item.count) return;
      this.$router.push({
        path: "/bazaar",
        query: {
          id: item.id,
          goodsId: item.goodsId,
          entrustId: item.entrust_id,
          hideAll: true,
          userId: item.userId,
        },
      });
      // this.getGoodmarketlist();
    },
    getPromotionList() {
      this.$api.promotionList().then((e) => {
        this.promotionList = e.data;
      });
    },
    beginTimer1() {
      this.ticker1 = setInterval(() => {
        //数据循环，因为要每一列数据都要倒计时，所以要对每一列的数据进行处理
        for (let i = 0, len = this.list.length; i < len; i++) {
          const item = this.list[i];
          if (item.runBeginTime > 0) {
            this.list[i].runBeginTime = this.list[i].runBeginTime - 1;
          } else if (item.runEndTime > 0) {
            this.list[i].runEndTime = this.list[i].runEndTime - 1;
          }
        }
      }, 1000);
    },
    getList() {
      let query = Object.assign(
        { token: localStorage.getItem("token") },
        this.goodsListParam
      );
      this.$api.applyGoodsList(query).then(
        (e) => {
          this.isInitLoading = false;
          if (e.code === 0) {
            setTimeout(() => {
              this.refreshing = false;
            }, 1000);
            this.loading = false;
            if (e.data.length === 0) {
              this.loadMore = false;
              this.goodsListParam.pageindex -= 1;
            }
            this.list = this.list.concat(e.data);
            if (
              this.list.filter((e) => e.runBeginTime || e.runEndTime).length
            ) {
              this.beginTimer1();
            }
          } else {
            return this.$toast(e.msg);
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    goTo(path) {
      if (this.isLogin) {
        this.$router.push(path);
      } else {
        this.$router.push("/login");
      }
    },
    goTo1(item) {
      this.$router.push("/purchaseDetail?id=" + item.id);
    },
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log("页面被挂起");
      } else {
        console.log("页面呼出");
        this.onRefresh();
      }
    },
    async popup() {
      const res = await this.$api.popup({
        token: localStorage.getItem("token"),
      });
      if (res.code === 0) {
        this.popupInfo = res.data;
        const showPopBox = window.localStorage.getItem("showPopBox");
        if (showPopBox != this.popupInfo.id) {
          this.isShowPopBox = true;
        }
      }
    },
    onRefresh() {
      this.goodsListParam.pageindex = 1;
      this.goodsListParam.pagesize = 20;
      if (this.ticker1) {
        clearInterval(this.ticker1);
      }
      this.Goodslist = [];
      this.albumList = [];
      this.list = [];
      this.loadMore = true;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      if (this.hotKey === "isHot") {
        this.hot(this.hotKey);
      } else if (this.hotKey === "isOnly") {
        this.blindBox(this.hotKey);
      } else if (this.hotKey === "isAlbum") {
        this.getAlbum(this.hotKey);
      } else if (this.hotKey === "isApply") {
        this.getList();
      }
    },
    getAlbum(key) {
      let token = Object.assign({ key: key }, this.goodsListParam);
      token.token = localStorage.getItem("token");
      this.$api.goodsAlbumList(token).then(
        (res) => {
          this.isInitLoading = false;
          setTimeout(() => {
            this.refreshing = false;
          }, 1000);
          this.loading = false;
          if (res.code == 0) {
            if (res.data.length === 0) {
              this.loadMore = false;
              this.goodsListParam.pageindex -= 1;
            }
            this.albumList = this.albumList.concat(res.data);
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    async unread() {
      const data = await this.$api.unread({
        token: localStorage.getItem("token"),
      });
      if (data.code === 0) {
        this.unreadInfo = data;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.goodsListParam.pageindex += 1;
      this.loading = true;
      if (this.loadMore) {
        if (this.hotKey === "isHot") {
          this.hot(this.hotKey);
        } else if (this.hotKey === "isOnly") {
          this.blindBox(this.hotKey);
        } else {
          this.getAlbum(this.hotKey);
        }
      } else {
        this.finished = true;
      }
    },
    hot(key) {
      let token = Object.assign(
        { key: key, token: localStorage.getItem("token") },
        this.goodsListParam
      );
      this.$api.Goodslist(token).then(
        (res) => {
          this.isInitLoading = false;
          setTimeout(() => {
            this.refreshing = false;
          }, 1000);
          this.loading = false;
          if (res.data.length === 0) {
            this.loadMore = false;
            this.goodsListParam.pageindex -= 1;
          }
          this.Goodslist = this.Goodslist.concat(res.data);
          this.iconlist = this.Goodslist;
          //  if (location.href.indexOf("#reloaded") == -1) {
          //     location.href = location.href + "#reloaded";
          //     location.reload();
          //     return;
          //     }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    blindBox(key) {
      let token = Object.assign({ key: key }, this.goodsListParam);
      token.token = localStorage.getItem("token");
      this.$api.blindBoxList(token).then(
        (res) => {
          this.isInitLoading = false;
          if (res.code == 0) {
            setTimeout(() => {
              this.refreshing = false;
            }, 1000);
            this.loading = false;
            if (res.data.length === 0) {
              this.loadMore = false;
              this.goodsListParam.pageindex -= 1;
            }
            this.Goodslist = this.Goodslist.concat(res.data);
            this.iconlist = this.Goodslist;
            this.particularsPrice = true;
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    beginTimer() {
      this.ticker = setInterval(() => {
        //数据循环，因为要每一列数据都要倒计时，所以要对每一列的数据进行处理
        for (let i = 0, len = this.Goodslist.length; i < len; i++) {
          const item = this.Goodslist[i];
          if (item.buyTime > 0) {
            this.Goodslist[i].buyTime = this.Goodslist[i].buyTime - 1;
          }
        }
      }, 1000);
    },
    ban(item) {
      if (item.type !== 3) {
        // 1藏品、2公告4申购 5盲盒
        switch (item.type) {
          case 1:
            this.$router.push(`/particulars?id=${item.linkId}&add=0`);
            break;
          case 2:
            this.$router.push(`/Banner?id=${item.linkId}`);
            break;
          case 4:
            this.$router.push(`/purchaseDetail?id=${item.linkId}`);
            break;
          case 5:
            this.$router.push(`/particulars?id=${item.linkId}&add=1`);
            break;

          default:
            break;
        }
      } else {
        if (item.draw_id) {
          this.$router.push(
            `/draw?id=${item.draw_id}&token=${localStorage.getItem("token")}`
          );
          return;
        }
        if (!item.link) return;
        if (localStorage.getItem("token")) {
          if (item.link.indexOf("?") != -1) {
            window.location.href = `${item.link}&token=${localStorage.getItem(
              "token"
            )}`;
          } else {
            window.location.href = `${item.link}?token=${localStorage.getItem(
              "token"
            )}`;
          }
        } else {
          window.location.href = item.link;
        }
      }
    },
    // 预售专区
    presell() {
      // Toast('即将呈现，敬请期待');
      this.$router.push("/Past");
      // this.$router.push("/purchase");
      // this.goTo('/purchase');
    },

    pushBanner() {
      window.localStorage.setItem("showPopBox", this.popupInfo.id);
      this.$router.push("/Banner?id=" + this.popupInfo.id);
    },

    closePopbox() {
      window.localStorage.setItem("showPopBox", this.popupInfo.id);
      this.isShowPopBox = false;
    },

    // 列表点击
    ternary(item) {
      this.add = item.key === "isHot" ? 1 : item.key === "isOnly" ? 0 : 2; // item.id;
      this.hotKey = item.key;
      // this.loadMore = true
      if (item.key == "isHot") {
        this.onRefresh();
        // this.hot(item.key);
      } else if (item.key == "isOnly") {
        this.onRefresh();
      } else {
        this.Goodslist = [];
        this.onRefresh();
      }
    },
    // 获取商品详情
    particulars(item) {
      this.$router.push({
        path: "/particulars",
        query: { id: item.id, add: this.add == 0 ? 1 : 0 },
      });
    },

    // banner详情
    banner(e) {
      this.$router.push({ path: "/Banner", query: { id: e } });
    },

    // 官方公告详情
    announcement() {
      this.$router.push({ path: "/Announcement" });
    },

    // 提示登录
    cancel() {
      this.none = false;
    },
    confirm() {
      this.none = false;
      this.$router.push("/login");
    },

    // 未实名提示
    cancel_one() {
      this.autonym = false;
    },

    confirm_tow() {
      this.$router.push("/Autonym");
    },
  },
};
</script>

<style scoped lang="less">
.tips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  .tipsBox {
    padding: 20px;
    box-sizing: border-box;
    width: 282px;
    height: 170px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      color: #000;
    }
    .info {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 80px;
        height: 28px;
        background: #0754d3;
        border-radius: 3px;
        border: 1px solid #0754d3;
        color: #fff;
        margin: 0 auto;
      }
    }
  }
}
.trumpet {
  width: 20px;
  // position: absolute;
}

#box {
  margin-bottom: 30px;
  position: relative;
}
.box1 {
  width: 341px;
  margin: 0 auto 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 14px 5px rgba(1, 15, 45, 0.05);
  padding-bottom: 14px;
  overflow: hidden;
  img {
    width: 100%;
    height: 200px;
    vertical-align: middle;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    padding: 13px 13px;
  }
  .subTitle {
    padding: 0 13px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #999999;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }
}
.trumpet_tow {
  padding-left: 10px;
}

.trumpet_box {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  // background-color: #8e8e8e;
}

.block {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  margin: 0 auto;
  margin-top: 60%;
}

.over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  .over_none {
    color: #0754d3;
    width: 74px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #cecece solid;
  }

  .over_en {
    color: #ffffff;
    width: 74px;
    text-align: center;
    background-color: #0754d3;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #0754d3 solid;
  }
}

/deep/.van-swipe__indicators {
  display: none;
}

/deep/.van-swipe__indicators--vertical {
  display: none;
}

.box {
  min-height: 110vh;
  margin-bottom: 50px;
  background: #f8f9fb;
  .banner {
    padding: 0 15px;
  }
  .announcement {
    margin: 0 15px;
    margin-top: 12px;
    background: #fff;
    border-radius: 10px;
  }
}

.block {
  background-color: #fff;
}
.popImgbg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    img {
      max-width: 90%;
    }
    i {
      display: block;
      width: 20px;
      height: 20px;
      margin: 20px auto 0;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
      &::after {
        display: block;
        content: "";
        height: 1px;
        width: 70%;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::before {
        display: block;
        content: "";
        height: 1px;
        width: 70%;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
.tag {
  font-size: 12px;
  position: absolute;
  top: 10px;
  display: inline-block;
  border-radius: 10px;
  padding: 0 10px;
  left: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #00e3ab;
  z-index: 1;
}
.predict {
  font-size: 12px;
  position: relative;
  top: 30px;
  border-radius: 10px;
  padding-left: 10px;
  left: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 225px;
  color: #00e3ab;
  // z-index: 1000;
}

.commodity {
  // background-color: #00e3ab;
  width: 341px;
  border-radius: 10px;
  margin-left: 17px;
  text-align: center;
  background: #fff;

  .commodity_img {
    border-radius: 10px 10px 0 0;
  }

  .commodity_name {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    line-height: 30px;
    padding-left: 16px;
  }

  .commodity_particulars {
    line-height: 20px;
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 20px;

    .particulars_fundamental {
      // margin-left: 10px;
      display: flex;
      font-size: 12px;

      .creation {
        font-size: 9px;
        color: #3596e5;
        white-space: nowrap;
        margin-left: 10px;
      }
    }

    .particulars_fundamental_img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .particulars_fundamental_name {
      // width: 55px;
      overflow: hidden;
      /*超出的部分隐藏起来。*/
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .particulars_price {
      font-size: 16px;

      span {
        font-weight: 900;
      }
    }

    img {
      object-fit: cover;
      // border-radius: 10px;
    }
  }

  img {
    width: 341px;
    height: 360px;
  }
}

.visited {
  color: blue;
}

.neirong {
  display: flex;
  padding-left: 16px;
  line-height: 18px;
  margin: 18px 0 15px;
  .list {
    font-size: 16px;
    color: #999999;
    margin-left: 14px;
    margin-right: 14px;
  }

  .listp {
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-left: 14px;
    margin-right: 14px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      height: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(64, 68, 241, 0.3), #4044f1);
    }
  }
}
.wenhua {
  background: url(../../assets/images/wenhua.png) no-repeat;
  height: 195px;
  width: 335px;
  background-size: contain;
  margin: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px 0;
  box-sizing: border-box;
  position: relative;
  .zhuanqu {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff0bd;
    position: absolute;
    right: 25px;
    top: 15px;
    padding-right: 10px;
    text-decoration: underline;
    &::before {
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      border-right: 2px solid #fff0bd;
      border-top: 2px solid #fff0bd;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  .wenhuaItem {
    position: relative;
    width: 143px;
    height: 143px;
    padding: 3px;
    border-radius: 5px;
    background: #fff;
    img {
      width: 100%;
      height: 100%;
    }
    .tag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      color: #f8f9fb;
      height: 20px;
      background: linear-gradient(99deg, #f46e0c 0%, #ffba00 99%);
      box-shadow: 0px 2px 4px 0px rgba(240, 11, 9, 0.28);
      border-radius: 5px 0px 13px 0px;
      &.right1 {
        border-radius: 0px 5px 0px 13px;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
      }
    }
  }
}
.but_two {
  display: flex;
  padding: 0 15px;
  margin-top: 12px;
  .announcement_but {
    img {
      width: 25px;
      height: 25px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    // width: 170px;
    height: 53px;
    color: #00e3ab;
    background-color: #e1fffe;
    margin: 0 12px;
    border-radius: 10px;
    font-size: 14px;
    flex: 1;
  }

  .yushouhuore_but {
    img {
      width: 25px;
      height: 25px;
    }
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 170px;
    height: 53px;
    color: #07cdff;
    background-color: #e0f7ff;
    border-radius: 10px;
    font-size: 14px;
  }
  .yushouhuore_but1 {
    img {
      width: 25px;
      height: 25px;
    }
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 170px;
    height: 53px;
    color: #f6b944;
    background-color: #fff5ef;
    border-radius: 10px;
    font-size: 14px;
  }

  img {
    width: 0.25rem;
    height: 0.25rem;
    display: inline-block;
    margin-right: 8px;
  }
}

.my-swipe {
  border-radius: 15px;
  overflow: hidden;
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
}

.con {
  width: 90%;
  height: 208px;
  margin-left: 17px;
  display: flex;
  overflow-x: auto;

  .list {
    width: 173px;
    height: 198px;
    margin: 0 5px;
    position: relative;
    color: rgba(255, 255, 255, 0.7);

    img {
      width: 173px;
      height: 198px;
    }

    .title {
      position: absolute;
      top: 18px;
      left: 15px;
      font-size: 17px;
      fon-width: 600;
    }

    .context {
      font-size: 13px;
      position: absolute;
      top: 121px;
      left: 15px;
    }

    .sum {
      position: absolute;
      left: 15px;
      top: 138px;
      font-size: 10px;
    }
  }
}

.mony_tow {
  font-size: 14px;
  position: relative;
  top: -25px;
  right: -35%;
}

.emptyBox {
  text-align: center;
  margin-top: 30px;
  font-size: 10px;
}
.right {
  width: 5px;
  height: 5px;
  border-top: 2px solid #999999;
  border-right: 2px solid #999999;
  transform: rotate(45deg);
}
.shengou {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 10px;
  box-shadow: 0 0 10px #eee;
  margin: 0 17px 20px;
  .img {
    position: relative;
    .over {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
      }
    }
    img {
      max-width: 100%;
    }
    .banner {
      height: 360px;
      width: 100%;
      object-fit: cover;
    }
    .timeOut {
      position: absolute;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #00e3ab;
      padding: 3px 10px;
      background: rgba(27, 27, 27, 0.6);
      border-radius: 15px;
      position: absolute;
      left: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      img {
        width: 13px;
        margin-right: 5px;
      }
    }
    .tag {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 3px 10px;
      background: rgba(0, 227, 171, 0.5);
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 10px;
      left: auto;
    }
  }
  .name {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    margin: 8px 0;
  }
  .subTitle {
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
</style>
